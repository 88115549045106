import styled from 'styled-components';
import { BREAKPOINT_MD } from '../../../../styles/Breakpoints';
import React, { useCallback, useState } from 'react';
import { useResetUserState } from '../../../../states/AppData';
import AlienIcon from '../../../icons/AlienIcon';
import ChevronIcon from '../../../icons/ChevronIcon';
import { Button } from '../../../common/Button';
import LinkHandler from '../../../common/LinkHandler';
import LogoutIcon from '../../../icons/LogoutIcon';
import LoginModal from '../../../content/user/LoginModal';
import { useNavigate } from 'react-router-dom';
import { useIsApiReady } from '../../../../services/ApiProvider';

const Wrapper = styled.div`
  position: relative;
`;

const MobileDropDownButton = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.375rem;

  ${BREAKPOINT_MD} {
    display: none;
  }
`;

const DesktopDropDownButton = styled(Button)`
  display: none;

  ${BREAKPOINT_MD} {
    display: flex;
  }
`;

const StyledAlienIcon = styled(AlienIcon)`
  font-size: 1.5rem;
`;

const ButtonLabel = styled.p`
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1;

  ${BREAKPOINT_MD} {
    font-size: 1rem;
  }
`;

const StyledChevronIcon = styled(ChevronIcon)`
  font-size: 1.5rem;
  transition: transform 0.25s ease-in-out;

  path {
    stroke-width: 1.5;
  }
`;

const DropDownMenu = styled.div<{ open: boolean }>`
  position: absolute;
  top: 3.25rem;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: var(--color-black-900);
  padding-inline: 1rem;
  border-radius: 0.625rem;
  border: 1px solid var(--color-text-default);
  opacity: ${({ open }) => (open ? 1 : 0)};
  visibility: ${({ open }) => (open ? 'initial' : 'hidden')};
  transition: opacity 0.25s ease-in-out;

  ${BREAKPOINT_MD} {
    top: calc(100% + 0.625rem);
    right: 0;
    min-width: 100%;
  }
`;

const MenuItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  padding: 0.5rem;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 700;
  width: 100%;
  white-space: nowrap;

  &:not(:last-child) {
    border-block-end: 1px solid var(--color-grey-800);
  }

  @media (hover: hover) {
    cursor: pointer;

    &:hover {
      color: var(--color-primary-750);
      text-decoration: none;
    }
  }
`;

const Divider = styled.span`
  width: 100%;
  height: 1px;
  background: var(--color-text-default);
`;

export default function ProfileMenu() {
  const [openLoginModal, setOpenLoginModal] = useState<boolean>(false);
  const [openDropDown, setOpenDropDown] = useState<boolean>(false);
  const resetUserState = useResetUserState();
  const isApiReady = useIsApiReady();

  const navigate = useNavigate();

  const loginHandler = useCallback(() => {
    if (isApiReady) {
      setOpenDropDown((state) => !state);
    } else {
      setOpenLoginModal(true);
    }
  }, [isApiReady, setOpenDropDown]);

  const logoutHandler = useCallback(() => {
    setOpenDropDown(false);
    resetUserState();
    navigate('/');
  }, []);

  return (
    <Wrapper>
      <MobileDropDownButton onClick={loginHandler}>
        <StyledAlienIcon eyeColor={isApiReady ? 'var(--color-signal-green)' : 'var(--color-signal-red)'} />
        <ButtonLabel>{isApiReady ? 'Profile' : 'Login'}</ButtonLabel>
      </MobileDropDownButton>

      <DesktopDropDownButton onClick={loginHandler}>
        <StyledAlienIcon eyeColor={isApiReady ? 'var(--color-signal-green)' : 'var(--color-signal-red)'} />
        <ButtonLabel>{isApiReady ? 'Profile' : 'Login'}</ButtonLabel>
        {isApiReady && <StyledChevronIcon style={{ transform: `rotateX(${openDropDown ? 180 : 0}deg)` }} />}
      </DesktopDropDownButton>

      <DropDownMenu open={openDropDown}>
        <MenuItem as={LinkHandler} onClick={() => setOpenDropDown(false)} to={'/profile'}>
          Account
        </MenuItem>

        <MenuItem as={LinkHandler} onClick={() => setOpenDropDown(false)} to={'/profile/address'}>
          Address
        </MenuItem>

        <MenuItem as={LinkHandler} onClick={() => setOpenDropDown(false)} to={'/profile/wallet'}>
          Wallets
        </MenuItem>

        <MenuItem as={LinkHandler} onClick={() => setOpenDropDown(false)} to={'/profile/history'}>
          History
        </MenuItem>

        <Divider />

        <MenuItem onClick={logoutHandler}>
          Logout
          <LogoutIcon />
        </MenuItem>
      </DropDownMenu>

      {openLoginModal && <LoginModal onClose={() => setOpenLoginModal(false)} />}
    </Wrapper>
  );
}
