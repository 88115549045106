import styled from 'styled-components';
import { useCallback, useEffect, useState } from 'react';
import { useIsApiReady } from '../../../services/ApiProvider';
import { useTrainingApi } from '../../../services/contract/stake';
import { useAccount } from 'wagmi';
import TrainingBox from './subcomponents/TrainingBox';
import TrainingTabSwitch from './subcomponents/TrainingTabSwitch';
import { ITrainingStatus } from '../../../types/stake';
import { IRewardStatus } from '../../../types/rewards';
import { BREAKPOINT_MD, BREAKPOINT_XXL } from '../../../styles/Breakpoints';
import { useRewardApi } from '../../../services/reward';
import { useRecoilState, useRecoilValue } from 'recoil';
import { AccountState, AuthTokenState } from '../../../states/AppData';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;

  ${BREAKPOINT_MD} {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 1.5rem;
  }

  ${BREAKPOINT_XXL} {
    grid-template-columns: repeat(19, 1fr);
    gap: 1.25rem;
  }
`;

export default function Training() {
  const [trainingStatus, setTrainingStatus] = useState<ITrainingStatus>();
  const [rewardStatus, setRewardStatus] = useState<IRewardStatus>();
  const [loading, setLoading] = useState<boolean>(true);
  const [accountState, setAccountState] = useRecoilState(AccountState);
  const autoTokenState = useRecoilValue(AuthTokenState);
  const { address } = useAccount();
  const isApiReady = useIsApiReady();
  const { getTrainingStatus } = useTrainingApi();
  const { getRewardStatus } = useRewardApi();
  const { claimCoins, stakeTokens } = useTrainingApi();

  async function handleStake() {
    try {
      const stakeResponse = await stakeTokens();
      trainingStatus &&
        setTrainingStatus({
          ...trainingStatus,
          tokens: (trainingStatus?.tokens || []).map((token) => ({
            ...token,
            staked: stakeResponse.tokenIds.includes(token.id),
          })),
        });
    } catch (error) {
      console.log('error staking tokens:', error);
    } finally {
      fetchData();
    }
  }

  async function handleClaim() {
    try {
      const claimResponse = await claimCoins();
      if (claimResponse?.newPoints && trainingStatus && accountState) {
        setAccountState({ ...accountState, account: { ...accountState.account, points: claimResponse.newPoints } });
      }
    } catch (error) {
      console.log('error claiming points:', error);
    } finally {
      fetchData();
    }
  }

  const fetchData = useCallback(async () => {
    try {
      const [rewardResponse, trainingResponse] = await Promise.all([getRewardStatus(), getTrainingStatus()]);

      setRewardStatus(rewardResponse);
      setTrainingStatus(trainingResponse);
    } catch (error) {
      console.log('error fetching trainings data:', error);
    } finally {
      setLoading(false);
    }
  }, [address, setRewardStatus, setTrainingStatus, setLoading]);

  useEffect(() => {
    isApiReady && autoTokenState?.token && fetchData();
  }, [isApiReady, autoTokenState?.token, fetchData]);

  return (
    <Wrapper>
      <TrainingBox trainingStatus={trainingStatus} stake={handleStake} claim={handleClaim} />

      <TrainingTabSwitch rewardStatus={rewardStatus} trainingStatus={trainingStatus} loading={loading} />
    </Wrapper>
  );
}
