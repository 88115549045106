import styled from 'styled-components';
import NewsSlider, { INewsSliderProps } from './NewsSlider';
import InfoCard, { IInfoCardProps } from './InfoCard';
import ShopRecommendations, { IShopRecommendationProps } from './ShopRecommendations';
import UserStats, { IUserStatsProps } from './UserStats';
import { BREAKPOINT_MD, BREAKPOINT_XXL } from '../../../styles/Breakpoints';
import LinkHandler from '../../common/LinkHandler';
import React, { useEffect, useState, useCallback } from 'react';
import { useDashboardApi } from '../../../services/dashboard';
import { useIsApiReady } from '../../../services/ApiProvider';
import LoginCTA from './LoginCTA';
import LoginModal from '../user/LoginModal';

const Wrapper = styled.div`
  display: grid;
  gap: 5rem 1.125rem;

  ${BREAKPOINT_XXL} {
    grid-template-columns: repeat(20, 1fr);
    row-gap: 1.5rem;
  }
`;

export const DashboardHeader = styled.div`
  display: flex;
  gap: 2rem;
  justify-content: space-between;
  align-items: center;
  margin-block-end: 1.25rem;
`;

export const DashboardHeadline = styled.h3`
  font-size: 1.875rem;
  font-weight: 700;
  text-transform: uppercase;
`;

export const DashboardLink = styled(LinkHandler)`
  font-size: 1rem;
  font-family: var(--font-family-headline);
  font-weight: 700;
  text-decoration: underline;

  &:hover {
    color: var(--color-primary-750);
  }

  ${BREAKPOINT_MD} {
    font-size: 1.125rem;
  }
`;

interface IDashboardProps {
  id: string;
  globalType: string;
  createdAt: string;
  updatedAt: string;
  news: INewsSliderProps;
  shopItems: IShopRecommendationProps;
  discordBanner: IInfoCardProps;
  userStats: IUserStatsProps;
}

export default function Dashboard() {
  const [data, setData] = useState<null | IDashboardProps>(null);
  const dashboardApi = useDashboardApi();
  const isApiReady = useIsApiReady();
  const [openLoginModal, setOpenLoginModal] = useState<boolean>(false);

  const fetchData = useCallback(async () => {
    const response = await dashboardApi.getDashboardData();
    setData(response);
  }, [dashboardApi]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    document.body.classList.toggle('no-scroll', openLoginModal);
  }, [openLoginModal]);

  if (data) {
    return (
      <Wrapper>
        {data?.news && <NewsSlider {...data.news} />}

        {data?.discordBanner && <InfoCard {...data.discordBanner} />}

        {data?.shopItems && <ShopRecommendations {...data.shopItems} />}

        {isApiReady ? <UserStats {...data.userStats} /> : <LoginCTA setOpenLoginModal={setOpenLoginModal} />}

        {openLoginModal && <LoginModal onClose={() => setOpenLoginModal(false)} />}
      </Wrapper>
    );
  } else {
    return <></>;
  }
}
