export const Translations = {
  general: {},
  navigation: {
    navigationLabelDashboard: 'Home',
    navigationLabelMint: 'Mint',
    navigationLabelInventory: 'Inventory',
    navigationLabelTraining: 'Training',
    navigationLabelShop: 'Shop',
    navigationLabelShopHistory: 'History',
    navigationLabelProfile: 'Profile',
    navigationLabelLogout: 'Disconnect',
  },
  cards: {
    attributes: {
      id: 'id',
      level: 'Tier',
      purchasedAt: 'purchased at',
      processedAt: 'processed at',
      quantity: 'quantity',
      revealAt: 'reveal date',
      amount: 'amount',
      collection: 'collection',
      rarity: 'rarity',
      dailyPoints: 'daily points',
      boost: 'boost',
      wallet: 'wallet',
    },
    countdown: {
      startsIn: 'starts in',
      endsIn: 'ends in',
      ended: 'sale ended',
    },
    gallery: {
      showAll: 'show all',
      showLess: 'show less',
    },
    comingSoon: {
      label: 'coming soon',
      title: 'new item',
    },
  },
  mint: {
    headline: 'Get your Booster here',
    mintLabel: 'MINT',
    termsAndConditions: `By connecting your wallet and using the Mooncourt Website, you agree to our <a href='https://mooncourt.xyz/terms-and-conditions'>Terms of Service</a> and <a href='https://mooncourt.xyz/privacy'>Privacy Policy</a>.`,
    successView: {
      headline: 'Thanks for minting',
      text: 'While your transaction is being processed feel free to look around the on rest of our website.',
      // todo: remove from translation
      link: {
        title: 'Home',
        href: '/',
        target: '_self',
      },
    },
    pausedView: {
      headline: 'First Batch Mint is over',
      text: 'More benefits and next mint dates for coming batches announced soon.',
      // todo: remove from translation
      link: {
        title: 'View inventory',
        href: '/',
        target: '_self',
      },
    },
    soldOutView: {
      headline: 'We are sold out',
      text: 'Thank you to everyone who participated in the mint. We got a bright future ahead of us.',
      // todo: remove from translation
      link: {
        title: 'Opensea',
        href: 'https://opensea.io/collection/mooncourt-booster',
        target: '_blank',
      },
    },
    notWhitelistedView: {
      headline: 'We are sorry!',
      text: "Seems like you didn't make it on our whitelist. Feel free to check out the booster collection on Opensea to be part of our exclusive community.",
      // todo: remove from translation
      link: {
        title: 'Opensea',
        href: 'https://opensea.io/collection/mooncourt-booster',
        target: '_blank',
      },
    },
  },
  claim: {
    headline: '',
    mintLabel: '',
    termsAndConditions: '',
    successView: {
      headline: '',
      text: '',
      // todo: remove from translation
      link: {
        title: '',
        href: '',
        target: '_blank',
      },
    },
    pausedView: {
      headline: '',
      text: '',
      // todo: remove from translation
      link: {
        title: '',
        href: '',
        target: '_blank',
      },
    },
    soldOutView: {
      headline: '',
      text: '',
      // todo: remove from translation
      link: {
        title: '',
        href: '',
        target: '_blank',
      },
    },
    notWhitelistedView: {
      headline: '',
      text: '',
      // todo: remove from translation
      link: {
        title: '',
        href: '',
        target: '_blank',
      },
    },
  },
  inventory: {
    headline: 'Inventory',
    selectAllLabel: 'Select All',
    openBoosterLabel: 'Open Booster',
    revealTermsAndConditions: `By connecting your wallet and using the Mooncourt Website, you agree to our <a href='https://mooncourt.xyz/terms-and-conditions'>Terms of Service</a> and <a href='https://mooncourt.xyz/privacy'>Privacy Policy</a>.`,
    placeholder: {
      headline: 'Inventory is empty',
      text: 'Currently we cannot find any NFTs associated with your account.',
    },
  },
  training: {
    headline: 'Training',
    labelTrainAll: 'Train all',
    labelClaimPoints: 'Claim Points',
    labelClaimablePoints: 'Claimable Points',
    labelPointRate: 'Moonpoints rate',
    placeholder: {
      headline: 'No ballers found!',
      text: 'Oops! It looks like you don’t have any Ballers to train. To start training and earning Moonpoints, you’ll need to acquire some Ballers first. Head to the marketplace or explore available packs to get started. Need help? Visit our Discord Server for more information. Happy gaming!',
    },
  },
  shop: {
    headline: 'Shop',
    placeholder: {
      headline: 'No Items found',
      text: 'Please check back again later.',
    },
  },
  shopHistory: {
    headline: 'Shop History',
    placeholder: {
      headline: 'No items found',
      text: 'We cannot allocate orders to your wallet.',
    },
  },
  cookieConsent: {
    text: 'We use Cookies in order to offer you an optimal service and to be able to improve in continuosly. by confirming “Accept all! you agree to the use of all cookies. By clicking the button “accept selection” you agree only to the cathégories you have selected.',
    buttonLabelAgree: 'agree',
    buttonLabelDisagree: 'disagree',
    buttonLabelAcceptAll: 'accept all',
    buttonLabelAcceptSelection: 'accept selection',
    buttonLabelReadMore: 'read more',
    buttonLabelReadLess: 'read less',
    consentItems: [
      {
        key: 'necessary',
        title: 'Necessary',
        description:
          'These cookies are required for basic site functionality and are therefore always enabled. These include cookies that allow you to be remembered as you explore the site within a single session or, if you request, from session to session. They help make the shopping cart and checkout process possible as well as assist in security issues and conforming to regulations.',
      },
      {
        key: 'performance',
        title: 'Performance',
        description:
          'These cookies allow us to improve the site’s functionality by tracking usage on this website. In some cases these cookies improve the speed with which we can process your request, allow us to remember site preferences you’ve selected. De-selecting these cookies may result in poorly-tailored recommendations and slow site performance.',
      },
      {
        key: 'social',
        title: 'Social Media and Advertising',
        description: `Social media cookies offer the possibility to connect you to your social networks and share content from our website through social media. Advertising cookies (of third parties) collect information to help better tailor advertising to your interests, both within and beyond this website. In some cases, these cookies involve the processing of your personal data. For more information about this processing of personal data, check our <a href='https://mooncourt.xyz/privacy'>Privacy & Cookie Policy</a>. De-selecting these cookies may result in seeing advertising that is not as relevant to you or you not being able to link effectively with Facebook, Twitter, or other social networks and/or not allowing you to share content on social media.`,
      },
    ],
  },
  sso: {
    headline: 'Sign in to the mooncourt',
    text: '<ul><li>Get access to the latest sneaker releases, raffles and giveaways</li><li>Collect Moonpoints to get higher chances on limited edition drops</li><li>Build your team of Ballers to unlock even more rewards</li></ul>',
    buttonLabel: 'Sign In',
  },
  login: {
    errorMessage: 'Wrong Password. Please try again!',
    redirectPlaceholder: 'You will be automatically redirected at any moment',
    metamaskPlaceholder: {
      headline: 'Metamask Browser is not supported',
      text: 'SingleSignOn is not supported in the MetaMask browser. Please sign in using Safari or Chrome. You can then connect your MetaMask wallet via Wallet-Connect.',
    },
  },
  walletConnect: {
    connectButton: 'Connect Wallet',
    connectedButton: 'Connected',
    connectHeadline: 'Connect your wallet',
    connectText: 'Select your wallet provider and connect your wallet to mint and get access to our exclusive holders area.',
    disconnectHeadline: 'Disconnect Wallet',
    disconnectText:
      'You are about to disconnect your wallet. Please keep in mind you wont be able to mint or access the holders area while disconnected',
    disconnectButton: 'Disconnect',
    yourWalletAddress: 'Your wallet',
    close: 'Close',
    deleteHeadline: 'Delete Wallet',
    deleteText: 'Are you sure you want to delete the Wallet? All assets linked to this wallet are no longer available!',
  },
  notifications: {
    internalServerError: {
      headline: 'ERROR',
      text: 'It seems like something went wrong please try again at a later point.',
    },
    pendingMintNotification: {
      headline: 'MINT PENDING',
      text: 'Your mint is currently pending, you can take a look at your transaction on ${ETHERSCAN}.',
    },
    successfulMintNotification: {
      headline: 'MINT SUCCESSFUL',
      text: 'Your mint was successful. Head over to reveal to unpack your minted boosters or head over to ${ETHERSCAN} to take a look at your transaction.',
    },
    failedMintNotification: {
      headline: 'MINT FAILED',
      text: 'Unfortunately your mint failed. To see what went wrong inspect the transaction on ${ETHERSCAN} and try again.',
    },
    pendingRevealNotification: {
      headline: 'REVEAL PENDING',
      text: 'Your reveal is currently pending, you can take a look at your transaction on ${ETHERSCAN}.',
    },
    successfulRevealNotification: {
      headline: 'REVEAL SUCCESSFUL',
      text: 'Your reveal was successful. Head over to train to take a look at all your ballers and stack them.',
    },
    failedRevealNotification: {
      headline: 'REVEAL FAILED',
      text: 'Unfortunately your reveal failed. To see what went wrong inspect the transaction on ${ETHERSCAN} and try again.',
    },
    successfulShopBuyNotification: {
      headline: 'PURCHASE SUCCESSFUL',
      text: 'The shop item has been purchased successfully.',
    },
    failedShopBuyNotification: {
      headline: 'BUY FAILED',
      text: 'Your shop item couldnt be bought.',
    },
    successfulRedeemNotification: {
      headline: 'REDEEM SUCCESSFUL',
      text: 'Your item has been redeemed.',
    },
    failedRedeemNotification: {
      headline: 'REDEEM FAILED',
      text: 'Your item cant be redeemed at the moment, please try again later.',
    },
    successfulSaveAddressNotification: {
      headline: 'ADDRESS SAVED SUCCESSFUL',
      text: 'Your address has been successfully saved.',
    },
    failedSaveAddressNotification: {
      headline: 'SAVING ADDRESS FAILED',
      text: 'Your address could not be saved. Please try again later.',
    },
    failedFetchAddressNotification: {
      headline: 'FETCHING ADDRESS FAILED',
      text: 'Unfortunately we cannot retrieve your address at the moment. Please try again later.',
    },
    successfulRaffleNotification: {
      headline: 'PURCHASE SUCCESSFUL',
      text: 'The raffle ticket has been purchased successfully.',
    },
    failedRaffleNotification: {
      headline: 'BUY FAILED',
      text: "Your raffle tickets couldn't be purchased. Please try again later.",
    },
    dailyRewardPointsAdded: {
      headline: 'DAILY REWARDS CLAIMED',
      text: `You earned {POINTS} Moonpoints for logging in today.`,
    },
    pointsAddedViaWalletConnect: {
      headline: 'POINTS ADDED',
      text: `You added {POINTS} Moonpoints by connecting your Wallet.`,
    },
    walletAlreadyConnected: {
      headline: 'CANT CONNECT WALLET',
      text: `This wallet is already linked to another account. If you are the owner of the wallet, please contact Mooncourt Support.`,
    },
    resendOneTimePassword: {
      headline: 'SEND ONE-TIME-PASSWORD',
      text: `A new One-Time-Password has been send to your E-Mail Address. Please check your inbox.`,
    },
    emailAlreadyUsed: {
      headline: 'EMAIL IS ALREADY IN USE',
      text: `This E-Mail is already being used.`,
    },
    connectWalletReminder: {
      headline: 'CONNECT YOUR WALLET',
      text: `<p>For the full mooncourt experience connect your wallet in <a href="/profile/wallet">your profile</a> and start earning moonpoints.</p>`,
    },
  },
  levelUp: {
    congratulationsHeadline: 'congratulations',
    headline: 'You have almost reached your goal',
    maxLevelText: 'You are already at the max level',
    optionToLevelUpText: 'You have the option to advance to level ',
    defaultText: 'You have almost reached level',
    costText: 'That would cost you ',
    levelUpButton: 'Level up now',
    acceptDialogText: 'ARE YOU SURE?',
    acceptDialogAcceptButton: 'yes',
    acceptDialogRejectButton: 'no',
  },
  tooltip: {
    level: {
      headline: 'level system',
      body: '<p><b>How does the level system work and how do you reach a higher level?</b></p><p>Your level in the Mooncourt corresponds to the number of Ballers you own. The more Ballers you have, the higher your level. Collect more Ballers to increase your level and unlock exclusive rewards.</p><br /><p><b>What are the benefits of the level system?</b></p><p>With each higher level, you gain access to more exclusive rewards and offers in the Mooncourt Store, including coveted items, limited editions, and much more.</p>',
    },
    momentum: {
      headline: 'momentum',
      body: '<p><b>What is the Momentum Streak?</b></p><p>Consistent training is key! Get on the Mooncourt every day to earn bonus Moonpoints.</p><br /><p><b>How does it work?</b></p><p>Build up your Momentum Streak by logging into the Mooncourt consecutively. Each new day you log in, you earn 5 more Moonpoints, up to a maximum of 35 Moonpoints per day for a streak of at least 7 days.</p><br/><p><b>What if I don’t log in every day?</b></p><p>If you miss a day, your Streak resets to a bonus of 5 Moonpoints per day. But don’t worry, you can start a new Streak immediately.</p>',
    },
    power: {
      headline: 'power',
      body: '<p><b>What is the Power Level?</b></p><p>Once you start your training, your Ballers will lose power over time due to training fatigue, similar to real life. This will affect the Moonpoints they generate per day.</p><br /><p><b>How does it work?</b></p><p>Every day, the Power level of your Ballers decreases by 5%. For example, if their Power level is at 90%, your Ballers will only generate 90% of their full potential Moonpoints.</p><br/><p><b>How can I reset the Power?</b></p><p>To restore your Power level to 100%, your Ballers need to rest. Simply click on “Rest & Claim MP” to reset the Power bar and claim your Moonpoints. The next day, your Ballers will automatically resume training. You will not lose any points from resting.</p>',
    },
  },
  detailPage: {
    availableAmount: 'Total Quantity',
    accordion: {
      purchaseInfoLabel: 'purchase info',
      productInfoLabel: 'product info',
      sizeChartLabel: 'size chart',
    },
    recommendations: {
      headline: 'you also might like',
      link: 'See more Items',
    },
    productInfo: {
      item: {
        buyingRights: {
          shipping: (price: number, shipping: number) =>
            `<p>You will acquire rights to buy the product for ${price}€ plus shipping fees.||| Confirmation and payment details will follow via mail.</p>`,
          noShipping: (price: number) =>
            `<p>You will acquire rights to buy the product for ${price}€ (shipping included).||| Confirmation and payment details will follow via mail.</p>`,
        },
        directBuy: {
          shipping: (shipping: number) =>
            `<p>This is a direct purchase of the product with Moonpoints. You will have to cover the shipping fee.||| Confirmation and payment details will follow via mail.</p>`,
          noShipping:
            '<p>This is a direct purchase of the product with Moonpoints and no further costs.||| A confirmation will follow via mail.</p>',
        },
      },
      raffle: {
        buyingRights: {
          shipping: (price: number, shipping: number) =>
            `<p>The raffle winners will acquire rights to buy the product for ${price}€ plus shipping fees.||| Winners will be drawn within 24h after the raffle ends. Your purchased raffle tickets will appear in your <a href="/inventory" target="_blank">inventory</a>. In case you have won the raffle, the prize will also appear in your <a href="/inventory" target="_blank">inventory</a>.</p>`,
          noShipping: (price: number) =>
            `<p>The raffle winners will acquire rights to buy the product for ${price}€ (shipping included).||| Winners will be drawn within 24h after the raffle ends. Your purchased raffle tickets will appear in your inventory. In case you have won the raffle, the prize will also appear in your inventory.</p>`,
        },
        directBuy: {
          shipping: (shipping: number) =>
            `<p>The winner of this raffle will receive the product and has to cover the shipping fees.||| Your purchased raffle tickets will appear in your <a href="/inventory" target="_blank">inventory</a>. Winners will be drawn within 24h after the raffle ends. In case you have won the raffle, the prize will also appear in your <a href="/inventory" target="_blank">inventory</a>.</p>`,
          noShipping:
            '<p>The winner of this raffle will receive the product at no further costs.||| Your purchased raffle tickets will appear in your <a href="/inventory" target="_blank">inventory</a>. Winners will be drawn within 24h after the raffle ends. In case you have won the raffle, the prize will also appear in your <a href="/inventory" target="_blank">inventory</a>.</p>',
        },
      },
    },
    buyModal: {
      headline: 'complete your order',
      terms: `<p>By placing this order, you agree to our <a href="https://mooncourt.xyz/terms-and-conditions" target="_blank">Terms and Conditions</a>.</p>`,
    },
  },
};
