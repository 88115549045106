import {
  CardAttribute,
  CardAttributes,
  CardBody,
  CardContainer,
  CardFooter,
  CardHeader,
  CardImageWrapper,
  CardLabel,
  CardPrice,
  CardTitle,
  InnerCardWrapper,
  ShopCardWrapper,
} from './CardStyles';
import Mooncourt from '../../icons/Mooncourt';
import { RaffleProps, RaffleTicketProps } from '../../../services/shop';
import { useMemo } from 'react';
import styled from 'styled-components';
import PointsIcon from '../../icons/PointsIcon';
import LinkHandler from '../../common/LinkHandler';
import CardCountdown from './CardCountdown';
import { Translations } from '../../../utils/Translations';

const StyledLinkHandler = styled(LinkHandler)`
  :hover {
    text-decoration: none;
  }
`;

const StyledInnerCardWrapper = styled(InnerCardWrapper)`
  background: var(--color-text-default);
  color: var(--color-text-highlight);
`;

interface IRaffleTicketCardProps extends Partial<RaffleTicketProps> {
  raffle: RaffleProps;
  loadLazy?: boolean;
}

export default function RaffleTicketCard(props: IRaffleTicketCardProps) {
  const { raffle, loadLazy, itemVariant, amount } = props;
  const { startDate, endDate, item, category } = raffle;
  const { name, previewImage, variants } = item;

  const ownedItem = useMemo(() => variants.find((item) => item.id === itemVariant), [itemVariant, variants]);

  const minPrice = useMemo(() => [...variants].sort((a, b) => a.price - b.price)[0].price, [variants]);

  const isAvailable = useMemo(() => {
    if (endDate && new Date(endDate).getTime() < new Date().getTime()) {
      return false;
    }

    return true;
  }, [endDate]);

  return (
    <CardContainer>
      <StyledLinkHandler to={`/shop/raffles/${raffle.id}`}>
        <ShopCardWrapper>
          <StyledInnerCardWrapper>
            <CardImageWrapper hasGradient={false} itemAvailable={isAvailable}>
              <img src={previewImage?.url} alt={name} loading={loadLazy ? 'lazy' : undefined} />
            </CardImageWrapper>

            <CardCountdown startDate={startDate} endDate={endDate} />

            <CardBody>
              <CardLabel>raffle</CardLabel>

              <CardHeader>
                <CardTitle>{name}</CardTitle>
              </CardHeader>

              <CardAttributes>
                <CardAttribute>
                  <p>{Translations.cards.attributes.level}</p>
                  <p>{category.label}</p>
                </CardAttribute>

                {ownedItem &&
                  ownedItem.attributes.map(({ value, type }, index) => (
                    <CardAttribute key={index}>
                      <p>{type}</p>
                      <p>{value}</p>
                    </CardAttribute>
                  ))}
                {amount && (
                  <CardAttribute>
                    <p>{Translations.cards.attributes.amount}</p>
                    <p>{amount}</p>
                  </CardAttribute>
                )}
              </CardAttributes>

              <CardFooter>
                <CardPrice>
                  <PointsIcon />
                  {ownedItem?.price || minPrice} MP
                </CardPrice>

                <Mooncourt />
              </CardFooter>
            </CardBody>
          </StyledInnerCardWrapper>
        </ShopCardWrapper>
      </StyledLinkHandler>
    </CardContainer>
  );
}
