import styled from 'styled-components';
import { useCallback, useRef } from 'react';
import LinkHandler from '../../common/LinkHandler';
import AnimatedBurgerButton from './subcomponents/AnimatedBurgerButton';
import { BREAKPOINT_MD, BREAKPOINT_SM } from '../../../styles/Breakpoints';
import IconLink from '../../common/IconLink';
import { Body2 } from '../../../styles/FontStyles';
import { useRecoilValue } from 'recoil';
import Chip from '../../common/Chip';
import PointsIcon from '../../icons/PointsIcon';
import Dialog from '../../common/Dialog';
import { Translations } from '../../../utils/Translations';
import { AccountState, useResetUserState } from '../../../states/AppData';
import { useIsApiReady } from '../../../services/ApiProvider';
import HomeIcon from '../../icons/HomeIcon';
import CardsIcon from '../../icons/CardsIcon';
import TrainingIcon from '../../icons/TrainingIcon';
import ShopIcon from '../../icons/ShopIcon';

const Wrapper = styled.nav<{ open: boolean }>`
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100 * var(--vh));
  transform: translateX(${({ open }) => (open ? '0' : '100%')});
  transition: transform 450ms ease;
  pointer-events: auto;
  padding-block-start: var(--nav-height);
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(0.625rem);

  ${BREAKPOINT_MD} {
    padding-block: 6rem 1.5rem;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.375rem;
  --size: 1.75rem;
`;

const ButtonLabel = styled.p`
  font-size: 0.75rem;
  line-height: 1;
  font-weight: 700;
`;

const OverflowWrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  gap: 2.5rem;
  overflow: auto;
  padding-inline: var(--content-indent);
  padding-block-end: var(--content-indent);
`;

const WalletInfoWrapper = styled.div`
  display: grid;
  gap: 0.75rem;
  margin-block-start: 1rem;

  ${BREAKPOINT_MD} {
    display: none;
  }

  div {
    width: 100%;
    justify-content: center;
  }
`;

const SocialItemsWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 1.5rem 2rem;
  border-radius: 0.625rem;
  gap: 2rem;

  ${BREAKPOINT_SM} {
    padding-inline: 3.5rem;
    width: max-content;
    align-self: center;
  }
`;

const NavItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
  margin-block: auto;

  ${BREAKPOINT_SM} {
    align-self: center;
  }
`;

const NavItem = styled.p<{ disabled: boolean }>`
  position: relative;
  font-family: var(--font-family-headline);
  line-height: 1.5;
  font-weight: 600;
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 2.75rem;
  text-transform: uppercase;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'initial')};
  color: ${({ disabled }) => (disabled ? 'var(--color-grey-650)' : 'var(--color-text-default)')};

  a {
    @media (hover: hover) {
      :hover {
        text-decoration: none;
        -webkit-text-stroke: 1px #f86259;
        color: black;

        :before {
          position: absolute;
          z-index: -1;
          top: 0;
          left: 0;
          content: '';
          width: 100%;
          height: 100%;
          background: radial-gradient(46.27% 46.27% ellipse at center, rgba(198, 44, 30, 0.73) 0%, rgba(253, 63, 32, 0) 100%),
            radial-gradient(46% 12% ellipse at center, rgba(198, 44, 30, 0.3) 0%, rgba(253, 63, 32, 0) 100%),
            radial-gradient(46% 5% ellipse at center, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0) 100%);
        }
      }
    }
  }
`;

const FooterItemsWrapper = styled.div`
  display: flex;
  align-self: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 0.5rem 1.5rem;
`;

const FooterLink = styled(LinkHandler)`
  ${Body2};
  font-family: var(--font-family-headline);
`;

interface IBurgerMenuProps {
  className?: string;
  onOpenLoginModal: () => void;
  openNav: boolean;
  setOpenNav: (value: boolean) => void;
}

interface INavItem {
  title: string;
  href: string;
  target: string;
  icon: JSX.Element;
  requiresLogin: boolean;
}

export const NavItems: INavItem[] = [
  {
    title: 'Home',
    href: '/',
    target: '_self',
    icon: <HomeIcon />,
    requiresLogin: false,
  },
  {
    title: 'Shop',
    href: '/shop',
    target: '_self',
    icon: <ShopIcon />,
    requiresLogin: false,
  },
  {
    title: 'Inventory',
    href: '/inventory',
    target: '_self',
    icon: <CardsIcon />,
    requiresLogin: true,
  },
  {
    title: 'Training',
    href: '/training',
    target: '_self',
    icon: <TrainingIcon />,
    requiresLogin: true,
  },
];

export const FooterLinks = [
  {
    title: 'Privacy Policy',
    href: 'https://www.mooncourt.xyz/private-policy',
    target: '_blank',
  },
  {
    title: 'Imprint',
    href: 'https://www.mooncourt.xyz/imprint',
    target: '_blank',
  },
  {
    title: 'Terms and conditions',
    href: 'https://www.mooncourt.xyz/terms-and-conditions',
    target: '_blank',
  },
];

export const NavigationIconLinks = [
  {
    icon: 'discord',
    link: {
      title: 'Discord',
      href: 'https://discord.gg/mooncourt',
      target: '_blank',
    },
    hasBorder: false,
  },
  {
    icon: 'opensea',
    link: {
      title: 'Opensea',
      href: 'https://opensea.io/collection/mooncourt-baller',
      target: '_blank',
    },
    hasBorder: false,
  },
  {
    icon: 'twitter',
    link: {
      title: 'Twitter',
      href: 'https://twitter.com/mooncourtxyz',
      target: '_blank',
    },
    hasBorder: false,
  },
  {
    icon: 'youtube',
    link: {
      title: 'Youtube',
      href: 'https://www.youtube.com/@Mooncourtxyz',
      target: '_blank',
    },
  },
  {
    icon: 'fortnite',
    link: {
      title: 'Fortnite',
      href: 'https://www.fortnite.com/@mooncourt.xyz',
      target: '_blank',
    },
    hasBorder: false,
  },
  {
    icon: 'twitch',
    link: {
      title: 'Twitch',
      href: 'https://www.twitch.tv/mooncourtxyz',
      target: '_blank',
    },
    hasBorder: false,
  },
];

export default function BurgerMenu(props: IBurgerMenuProps) {
  const { className, onOpenLoginModal, openNav, setOpenNav } = props;

  const dialogRef = useRef<HTMLDialogElement>(null);
  const accountState = useRecoilValue(AccountState);
  const isApiReady = useIsApiReady();
  const resetUserState = useResetUserState();

  function toggleDialog() {
    if (dialogRef?.current) {
      dialogRef.current.open ? dialogRef.current.close() : dialogRef.current.showModal();
    }
  }

  const handleLoginClick = useCallback(() => {
    if (isApiReady) {
      resetUserState();
    } else {
      setOpenNav(false);
      onOpenLoginModal();
    }
  }, [isApiReady, onOpenLoginModal]);

  return (
    <>
      <ButtonWrapper>
        <AnimatedBurgerButton open={openNav} setOpen={setOpenNav} className={className} />
        <ButtonLabel>Menu</ButtonLabel>
      </ButtonWrapper>

      <Wrapper open={openNav}>
        <OverflowWrapper>
          {isApiReady && (
            <WalletInfoWrapper>
              <Chip>
                <PointsIcon />
                {accountState?.account?.points} MP
              </Chip>
            </WalletInfoWrapper>
          )}

          <NavItemsWrapper>
            {NavItems?.map((item, index) => (
              <NavItem key={index} disabled={item.requiresLogin && !isApiReady}>
                <LinkHandler to={item.href} {...item} onClick={() => setOpenNav(false)}>
                  {item.title}
                </LinkHandler>
              </NavItem>
            ))}
          </NavItemsWrapper>

          <SocialItemsWrapper>{NavigationIconLinks?.map((item, index) => <IconLink {...item} key={index} />)}</SocialItemsWrapper>

          <FooterItemsWrapper>
            {FooterLinks?.map((link, index) => (
              <FooterLink key={index} to={link.href} {...link}>
                {link.title}
              </FooterLink>
            ))}
          </FooterItemsWrapper>
        </OverflowWrapper>

        <Dialog ref={dialogRef} translation={Translations.tooltip.level} toggleDialog={toggleDialog} />
      </Wrapper>
    </>
  );
}
