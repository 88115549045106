import React, { FC, useCallback, useContext, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSSOApi } from '../../services/sso';
import { useSetRecoilState } from 'recoil';
import { AuthTokenState } from '../../states/AppData';
import useToast from '../../hooks/useToast';
import { Notifications } from '../layout/app/Layout';
import styled, { keyframes } from 'styled-components';
import { Translations } from '../../utils/Translations';

const Wrapper = styled.div`
  align-self: center;
  justify-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  max-width: 40rem;
  border: 1px solid white;
  backdrop-filter: blur(6px);
  border-radius: 1.25rem;
  padding: 2.5rem 2rem;
`;

const Rotation = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

const CustomSpinner = styled.span`
  width: 2.5rem;
  height: 2.5rem;
  border: 0.25rem solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: ${Rotation} 1s linear infinite;
`;

const Headline = styled.h2`
  font-size: 2rem;
  line-height: 1.25;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
`;

export interface ILoginReturnPageProps {
  type: 'GOOGLE' | 'APPLE' | 'DISCORD';
  urlAuthCodeParam: string;
}

export const LoginReturnPage: FC<ILoginReturnPageProps> = ({ type, urlAuthCodeParam }: ILoginReturnPageProps) => {
  const [searchParams] = useSearchParams();
  const ssoApi = useSSOApi();
  const setAuthTokenState = useSetRecoilState(AuthTokenState);
  const { addErrorToast } = useToast();
  const notifications = useContext(Notifications);
  const navigate = useNavigate();

  const verifyCallback = useCallback(async (verifyToken: string, type: 'GOOGLE' | 'APPLE' | 'DISCORD') => {
    try {
      let validatedToken = null;

      if (type === 'GOOGLE') {
        validatedToken = await ssoApi.googleLogin(verifyToken);
      } else if (type === 'APPLE') {
        validatedToken = await ssoApi.appleLogin(verifyToken);
      } else if (type === 'DISCORD') {
        validatedToken = await ssoApi.discordLogin(verifyToken);
      }

      if (validatedToken?.error) {
        notifications && addErrorToast(notifications.internalServerError);
      }

      if (validatedToken && validatedToken?.token) {
        setAuthTokenState({ token: validatedToken.token });
      }
    } catch (error) {
      console.log('verifyCallback => error =>', error);
      notifications && addErrorToast(notifications.internalServerError);
    } finally {
      navigate('/');
    }
  }, []);

  useEffect(() => {
    if (searchParams) {
      const code = searchParams.get(urlAuthCodeParam);
      if (code) {
        verifyCallback(code, type);
      }
    }
  }, [searchParams]);

  return (
    <Wrapper>
      <CustomSpinner />
      <Headline>{Translations.login.redirectPlaceholder}</Headline>
    </Wrapper>
  );
};
