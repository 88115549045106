import DetailBackButton from './subcomponents/DetailBackButton';
import { useParams } from 'react-router-dom';
import { useContext, useEffect, useMemo, useState } from 'react';
import { ShopItemProps, ShopItemVariant, useShopApi } from '../../../services/shop';
import { useIsApiReady } from '../../../services/ApiProvider';
import DetailImageGallery from './subcomponents/DetailImageGallery';
import DetailPageTags from './subcomponents/DetailPageTags';
import DetailPageCountdown from './subcomponents/DetailPageCountdown';
import {
  DetailPageContainer,
  DetailPageContent,
  DetailPageInfoWrapper,
  DetailPageLayout,
  DetailPageLoadingAnimation,
  DetailPagePurchaseInfo,
  DetailPageTitle,
} from './subcomponents/DetailPageStyledComponents';
import DetailPagePrice from './subcomponents/DetailPagePrice';
import DetailPageVariantSelect from './subcomponents/DetailPageVariantSelect';
import DetailPageAccordion from './subcomponents/DetailPageAccordion';
import DetailPageRecommendations from './subcomponents/DetailPageRecommendations';
import { Translations } from '../../../utils/Translations';
import { useAccount } from 'wagmi';
import { useRecoilValue } from 'recoil';
import { useForm } from 'react-hook-form';
import { Notifications } from '../../layout/app/Layout';
import useToast from '../../../hooks/useToast';
import BuyOrConnectButton from './subcomponents/BuyOrConnectButton';
import DetailPageBuyModal from './subcomponents/DetailPageBuyModal';
import InfoIcon from '../../icons/InfoIcon';
import Wysiwyg from '../../common/Wysiwyg';
import { AccountState } from '../../../states/AppData';
import { useUserApi } from '../../../services/user';

export default function ShopItemDetail() {
  const { id } = useParams();

  const shopApi = useShopApi();
  const isApiReady = useIsApiReady();
  const { address } = useAccount();
  const { addToast } = useToast();
  const userApi = useUserApi();

  const notifications = useContext(Notifications);
  const accountState = useRecoilValue(AccountState);

  const [loading, setLoading] = useState<boolean>(true);
  const [openBuyModal, setOpenBuyModal] = useState(false);
  const [shopItem, setShopItem] = useState<ShopItemProps | null>(null);
  const [recommendations, setRecommendations] = useState<ShopItemProps[]>([]);
  const [selectedVariant, setSelectedVariant] = useState<ShopItemVariant | null>(null);

  const form = useForm();
  const selectedVariantId = form.watch('variant');

  useEffect(() => {
    if (shopItem) {
      setSelectedVariant(shopItem.variants.find(({ id }) => id === selectedVariantId) || null);
    }
  }, [selectedVariantId, shopItem]);

  const availableAmount = useMemo(() => shopItem?.variants.reduce((acc, curr) => acc + curr.availableAmount, 0), [shopItem]);

  // TODO @daniel add correct shippingCost
  const shippingCost = useMemo(() => {
    return 0;
  }, [shopItem]);

  const purchaseInfo = useMemo(() => {
    if (shopItem) {
      if (shopItem.paymentForProductRequired && shopItem.paymentForShippingRequired) {
        return Translations.detailPage.productInfo.item.buyingRights.shipping(shopItem.retailPrice, shippingCost);
      } else if (shopItem.paymentForProductRequired && !shopItem.paymentForShippingRequired) {
        return Translations.detailPage.productInfo.item.buyingRights.noShipping(shopItem.retailPrice);
      } else if (!shopItem.paymentForProductRequired && shopItem.paymentForShippingRequired) {
        return Translations.detailPage.productInfo.item.directBuy.shipping(shippingCost);
      } else if (!shopItem.paymentForProductRequired && !shopItem.paymentForShippingRequired) {
        return Translations.detailPage.productInfo.item.directBuy.noShipping;
      }
    } else return '';
  }, [shopItem]);

  const saleStarted = useMemo(() => {
    const startDate = shopItem?.startDate;
    return startDate ? Boolean(new Date(startDate) < new Date()) : true;
  }, [shopItem]);

  const saleEnded = useMemo(() => {
    const endDate = shopItem?.endDate;
    return endDate ? Boolean(new Date(endDate) < new Date()) : false;
  }, [shopItem]);

  const checkWhitelist = useMemo(() => {
    const whitelist = shopItem?.allowList || [];
    const isWhitelistActive = Boolean(whitelist.length > 0);
    const isWalletOnWhitelist = Boolean(whitelist.find(({ wallet }) => wallet === address));
    return Boolean(!isWhitelistActive || (isWhitelistActive && isWalletOnWhitelist));
  }, [shopItem, address]);

  const isPurchasable = useMemo(() => {
    const accountLevel = Array.isArray(accountState?.account.level) ? accountState?.account.level : ['free'];
    const hasCorrectLevel = accountLevel?.includes('legacy') || Boolean(accountLevel?.includes(shopItem?.category?.type!));
    const hasEnoughCoins = Boolean(
      accountState?.account.points && selectedVariant && accountState.account.points >= selectedVariant?.price,
    );
    const itemInStock = Boolean(selectedVariant && selectedVariant?.availableAmount > 0);
    const reachedMaxAmount = Boolean((shopItem?.ownedItems || 0) >= (shopItem?.maxPurchasableQuantity || 1));

    return Boolean(
      hasCorrectLevel &&
        hasEnoughCoins &&
        checkWhitelist &&
        saleStarted &&
        !saleEnded &&
        itemInStock &&
        !reachedMaxAmount &&
        selectedVariant &&
        shopItem?.displayInShop,
    );
  }, [accountState, selectedVariant, shopItem, checkWhitelist, saleEnded, saleStarted]);

  const fetchHistoryItem = useMemo(
    () => async () => {
      try {
        const response = await shopApi.getShopItem(id!);
        setShopItem(response);
      } catch (error) {
        console.error('Error fetching product list:', error);
      } finally {
        setLoading(false);
      }
    },
    [id],
  );

  const fetchRecommendations = useMemo(
    () => async () => {
      try {
        const response = await shopApi.getRecommendations(id);
        setRecommendations(response);
      } catch (error) {
        console.error('Error fetching recommendations:', error);
      }
    },
    [id],
  );

  useEffect(() => {
    fetchHistoryItem();
    fetchRecommendations();
  }, []);

  const buy = async () => {
    if (isApiReady) {
      try {
        await shopApi.buyItem(id!, selectedVariant!.id);
        if (notifications?.successfulShopBuyNotification) {
          addToast({
            ...notifications.successfulShopBuyNotification,
            type: 'success',
          });
        }
      } catch (error) {
        console.log(error);
        if (notifications?.failedShopBuyNotification) {
          addToast({
            ...notifications.failedShopBuyNotification,
            type: 'error',
          });
        }
      } finally {
        setOpenBuyModal(false);
        await fetchHistoryItem();
        await userApi.refreshAccountState();
        // TODO @daniel redirect to profile or history?
      }
    }
  };

  return (
    <DetailPageLayout>
      <DetailBackButton />

      {loading ? (
        <DetailPageLoadingAnimation />
      ) : (
        shopItem && (
          <>
            <DetailPageContainer>
              <DetailImageGallery images={selectedVariant ? selectedVariant.images : shopItem.images} />

              <DetailPageContent form={form} onSubmit={() => setOpenBuyModal(true)}>
                <DetailPageTags tags={[shopItem.category.label, shopItem.paymentForProductRequired ? 'Buying Right' : 'Direct Purchase']} />

                <DetailPageTitle>{shopItem.name}</DetailPageTitle>

                <DetailPageInfoWrapper>
                  <DetailPageCountdown startDate={shopItem.startDate} endDate={shopItem.endDate} availableAmount={availableAmount} />
                </DetailPageInfoWrapper>

                <DetailPagePrice price={selectedVariant?.price || shopItem.price} />

                <DetailPagePurchaseInfo>
                  <InfoIcon /> <Wysiwyg content={purchaseInfo?.split('|||')[0] || ''} />
                </DetailPagePurchaseInfo>

                <DetailPageVariantSelect
                  variants={shopItem.variants}
                  disabled={!saleStarted || saleEnded || !shopItem.displayInShop}
                  availableAmount={availableAmount}
                />

                <BuyOrConnectButton
                  isPurchasable={isPurchasable}
                  label={'Buy Item'}
                  maxAmount={shopItem.maxPurchasableQuantity}
                  ownedItems={shopItem.ownedItems}
                />

                <DetailPageAccordion purchaseInfo={purchaseInfo || ''} productInfo={shopItem.description || ''} />
              </DetailPageContent>
            </DetailPageContainer>

            <DetailPageRecommendations recommendations={recommendations} />

            {openBuyModal && selectedVariant && (
              <DetailPageBuyModal
                close={() => setOpenBuyModal(false)}
                buy={buy}
                item={shopItem}
                purchaseInfo={purchaseInfo || ''}
                selectedVariant={selectedVariant}
              />
            )}
          </>
        )
      )}
    </DetailPageLayout>
  );
}
