import React, { useContext } from 'react';
import styled from 'styled-components';
import OTPInput from 'react-otp-input';
import { TextInputStyles } from '../../common/form/TextInput';
import { FontFamilies } from '../../../styles/Fonts';
import { useAccountApi } from '../../../services/account';
import { useRecoilValue } from 'recoil';
import { AccountState } from '../../../states/AppData';
import useToast from '../../../hooks/useToast';
import { Notifications } from '../../layout/app/Layout';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const Info = styled.p`
  font-size: 1rem;
  margin-block-end: 1.5rem;
`;

const InputLabel = styled.p`
  font-size: 0.875rem;
  font-weight: 600;
  margin-block-end: 0.5rem;
`;

const CustomInput = styled.input`
  ${TextInputStyles};
  width: 40px !important;
  box-shadow: none;

  /* Chrome, Safari, Edge, Opera */

  &&::-webkit-outer-spin-button,
  &&::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */

  &&[type='number'] {
    -moz-appearance: textfield;
  }
`;

const Hint = styled.p`
  font-size: 0.75rem;
  color: var(--color-grey-600);
  margin-block-end: 0.75rem;
`;

const ResentButton = styled.button`
  font-family: ${FontFamilies.headline};
  font-size: 0.875rem;
  font-weight: 600;
  margin-block-end: 1.25rem;
  text-decoration: underline;
`;

interface IAccountConfirmationProps {
  otp?: string;
  setOtp: (value: string) => void;
  email: string;
}

export default function AccountConfirmation(props: IAccountConfirmationProps) {
  const { otp, setOtp, email } = props;
  const accountState = useRecoilValue(AccountState);
  const { requestNewOtp } = useAccountApi();
  const { addToast, addErrorToast } = useToast();
  const notifications = useContext(Notifications);

  async function resetVerificationCode() {
    try {
      const response = await requestNewOtp(email || accountState?.account.email || '');
      if (response?.success) {
        notifications && addToast({ ...notifications.resendOneTimePassword, type: 'success' });
      }
    } catch (error) {
      console.error(error);
      notifications && addErrorToast(notifications.internalServerError);
    }
  }

  return (
    <Wrapper>
      <Info>
        Check your inbox for verification code sent to <b>{email}</b>
      </Info>

      <InputLabel>Enter Code</InputLabel>

      <OTPInput
        inputType={'number'}
        value={otp}
        onChange={setOtp}
        renderInput={(props) => <CustomInput {...props} />}
        numInputs={6}
        containerStyle={{
          gap: 12,
          marginBlockEnd: 38,
        }}
      />

      <Hint>
        No code in your inbox? Please check your spam folder and if your address is written correctly. We can also try to resend the code.
      </Hint>

      <ResentButton type={'button'} onClick={resetVerificationCode}>
        Resend Code
      </ResentButton>
    </Wrapper>
  );
}
