import styled from 'styled-components';
import AppleSignin from 'react-apple-signin-auth';
import React, { useCallback, useContext, useEffect } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { Button } from '../../common/Button';
import { useOAuthWindow } from '../../../utils/oAuthWindow';
import { useSSOApi } from '../../../services/sso';
import GoogleIcon from '../../icons/GoogleIcon';
import DiscordIcon from '../../icons/DiscordIcon';
import AppleIcon from '../../icons/AppleIcon';
import { useSetRecoilState } from 'recoil';
import { AuthTokenState } from '../../../states/AppData';
import useToast from '../../../hooks/useToast';
import { Notifications } from '../../layout/app/Layout';

const Wrapper = styled.div`
  display: grid;
  gap: 12px;
  overflow: hidden;
`;

const IconButton = styled(Button)`
  justify-content: center;
  width: 100%;
  flex-shrink: 0;

  svg {
    font-size: 1.5rem;
  }
`;

interface ISSOButtonProps {
  onClose: () => void;
}

export default function SSOButtons(props: ISSOButtonProps) {
  const { onClose } = props;
  const ssoApi = useSSOApi();
  const { openWindow, buildDiscordUrl, buildAppleOAuthUrl } = useOAuthWindow();
  const setAuthTokenState = useSetRecoilState(AuthTokenState);
  const { addErrorToast } = useToast();
  const notifications = useContext(Notifications);

  const verifyCallback = useCallback(async (verifyToken: string, type: 'GOOGLE' | 'APPLE' | 'DISCORD') => {
    try {
      let validatedToken = null;

      if (type === 'GOOGLE') {
        validatedToken = await ssoApi.googleLogin(verifyToken);
      } else if (type === 'APPLE') {
        validatedToken = await ssoApi.appleLogin(verifyToken);
      } else if (type === 'DISCORD') {
        validatedToken = await ssoApi.discordLogin(verifyToken);
      }

      if (validatedToken?.error) {
        notifications && addErrorToast(notifications.emailAlreadyUsed);
      }

      if (validatedToken && validatedToken?.token) {
        setAuthTokenState({ token: validatedToken.token });
        onClose();
      }
    } catch (error) {
      console.log('verifyCallback => error =>', error);
    }
  }, []);

  const ssoAuthCodePostMessageListener = async (event: MessageEvent<any>) => {
    if (event?.origin === window.location.origin && event?.type === 'message' && event?.data?.type === 'sso-auth-code') {
      await verifyCallback(event.data.code, event.data.ssoType);
    }
  };

  const googleLoginHandler = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      if (tokenResponse.code) {
        await verifyCallback(tokenResponse.code, 'GOOGLE');
      }
    },
    flow: 'auth-code',
    ux_mode: 'redirect',
    redirect_uri: `${process.env.REACT_APP_OAUTH_REDIRECT_BASE_URL}google`,
  });

  const discordLoginHandler = () => {
    const url = buildDiscordUrl();
    openWindow(url);
  };

  const appleLoginHandler = () => {
    const url = buildAppleOAuthUrl();
    openWindow(url);
  };

  useEffect(() => {
    window.addEventListener('message', ssoAuthCodePostMessageListener);

    return () => {
      window.removeEventListener('message', ssoAuthCodePostMessageListener);
    };
  }, []);

  return (
    <Wrapper>
      <IconButton onClick={() => appleLoginHandler()} type={'button'}>
        <AppleIcon />
        Sign in with Apple
      </IconButton>

      <IconButton onClick={googleLoginHandler} type={'button'}>
        <GoogleIcon />
        Sign in with Google
      </IconButton>

      <IconButton onClick={discordLoginHandler} type={'button'}>
        <DiscordIcon style={{ fontSize: '1rem' }} />
        Sign in with Discord
      </IconButton>
    </Wrapper>
  );
}
