import styled from 'styled-components';
import { Button } from '../../../common/Button';
import { IConnectedWallet } from '../../../../states/AppData';
import DeleteIcon from '../../../icons/DeleteIcon';
import { useCallback, useMemo } from 'react';
import { FontFamilies } from '../../../../styles/Fonts';
import { useAccount, useDisconnect } from 'wagmi';
import { Glass } from '../../../../styles/Custom';

const Wrapper = styled.div`
  ${Glass};
  display: flex;
  flex-direction: column;
  padding: 1.25rem;
`;

const Top = styled.div`
  display: flex;
  align-items: center;
  margin-block-end: 0.75rem;
  height: 2rem;
`;

const Indicator = styled.span<{ isConnected: boolean }>`
  width: 0.75rem;
  height: 0.75rem;
  background: var(--color-signal-${({ isConnected }) => (isConnected ? 'green' : 'red')});
  border-radius: 100%;
  margin-inline-end: 0.75rem;
  margin-block-start: 0.125rem;
`;

const Address = styled.h4`
  font-family: ${FontFamilies.headline};
  font-size: 1.5rem;
  line-height: 1;
  font-weight: 600;
  text-transform: uppercase;
`;

const DeleteButton = styled.button`
  width: 2rem;
  height: 2rem;
  margin-inline-start: auto;
  font-size: 1.5rem;

  @media (hover: hover) {
    :hover {
      color: var(--color-primary-750);
    }
  }
`;

const Assets = styled.div`
  display: flex;
  gap: 0.25rem 0.375rem;
  margin-block-end: 1.75rem;
  flex-wrap: wrap;
`;

const Asset = styled.p`
  color: var(--color-grey-600);
  font-size: 1.125rem;
  line-height: 1.1;
  flex-wrap: nowrap;

  &:not(:last-child) {
    ::after {
      content: ',';
    }
  }
`;

const StyledButton = styled(Button)`
  width: 100%;
  justify-content: center;
  margin-block-start: auto;
`;

interface IWalletCardProps extends IConnectedWallet {
  onConnect: () => void;
  onDelete: () => void;
}

export default function WalletCard(props: IWalletCardProps) {
  const { wallet, ballers, booster, comets, onConnect, onDelete } = props;
  const { address } = useAccount();
  const { disconnect } = useDisconnect();

  const formattedAddress = useMemo(() => {
    return wallet.slice(0, 4) + '...' + wallet.slice(wallet.length - 4);
  }, [wallet]);

  const handleButtonClick = useCallback(() => {
    if (address === wallet) {
      disconnect();
    } else {
      disconnect();
      onConnect();
    }
  }, [address, wallet, disconnect, onConnect]);

  return (
    <Wrapper>
      <Top>
        <Indicator isConnected={wallet === address} />

        <Address>{formattedAddress}</Address>

        {wallet === address && (
          <DeleteButton onClick={onDelete}>
            <DeleteIcon />
          </DeleteButton>
        )}
      </Top>

      <Assets>
        <Asset>
          {booster} Booster{booster === 1 ? '' : 's'}
        </Asset>
        <Asset>
          {ballers} Baller{ballers === 1 ? '' : 's'}
        </Asset>
        <Asset>
          {comets} Comet{comets === 1 ? '' : 's'}
        </Asset>
      </Assets>

      <StyledButton onClick={handleButtonClick}>{address === wallet ? 'Disconnect' : 'Connect'}</StyledButton>
    </Wrapper>
  );
}
