import { createBrowserRouter, RouterProvider, Outlet } from 'react-router-dom';
import { FC, useLayoutEffect } from 'react';
import useFeatureToggle from './hooks/useFeatureToggle';
import Layout from './components/layout/app/Layout';
import Training from './components/content/training';
import Inventory from './components/content/inventory';
import BallerDetail from './components/content/ballers/BallerDetail';
import { Shop } from './components/content/shop';
import { GlobalStyles } from './styles/GlobalStyles';
import { RecoilRoot } from 'recoil';
import { Mint } from './components/content/mint';
import { Claim } from './components/content/claim';
import Page404 from './components/content/404';
import AccessBlocked from './components/content/AccessBlocked';
import Dashboard from './components/content/dashboard';
import { ApiProvider } from './services/ApiProvider';
import ShopItemDetail from './components/content/shop/ShopItemDetail';
import RaffleDetail from './components/content/shop/RaffleDetail';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { LoginReturnPage } from './components/auth/LoginReturnPage';
import Profile from './components/content/profile';
import UserRoute from './components/layout/guards/UserRoute';
import AddressTab from './components/content/profile/AddressTab';
import WalletTab from './components/content/profile/WalletTab';
import HistoryTab from './components/content/profile/HistoryTab';
import AccountTab from './components/content/profile/AccountTab';

const App: FC = () => {
  const { TRAINING_ACTIVE, SHOP_ACTIVE, CLAIM_ACTIVE, MINT_ACTIVE } = useFeatureToggle();

  const router = createBrowserRouter([
    {
      path: '/',
      element: (
        <ApiProvider>
          <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID!}>
            <Layout>
              <Outlet />
            </Layout>
          </GoogleOAuthProvider>
        </ApiProvider>
      ),
      children: [
        {
          path: '/',
          element: <Dashboard />,
        },
        {
          path: '/inventory',
          element: (
            <UserRoute>
              <Inventory />
            </UserRoute>
          ),
        },
        {
          path: '/profile',
          element: (
            <UserRoute>
              <Profile />
            </UserRoute>
          ),
          children: [
            {
              index: true,
              element: <AccountTab />,
            },
            {
              path: 'account',
              element: <AccountTab />,
            },
            {
              path: 'address',
              element: <AddressTab />,
            },
            {
              path: 'wallet',
              element: <WalletTab />,
            },
            {
              path: 'history',
              element: <HistoryTab />,
            },
          ],
        },
        {
          path: '*',
          element: <Page404 />,
        },
        {
          path: 'access-blocked',
          element: <AccessBlocked />,
        },
        TRAINING_ACTIVE
          ? {
              path: 'training',
              element: (
                <UserRoute>
                  <Training />
                </UserRoute>
              ),
            }
          : {},
        SHOP_ACTIVE
          ? {
              path: 'shop',
              element: <Shop />,
            }
          : {},
        SHOP_ACTIVE
          ? {
              path: 'shop/items/:id',
              element: <ShopItemDetail />,
            }
          : {},
        SHOP_ACTIVE
          ? {
              path: 'shop/raffles/:id',
              element: <RaffleDetail />,
            }
          : {},
        {
          path: '/login/redirect/discord',
          element: <LoginReturnPage type={'DISCORD'} urlAuthCodeParam={'code'} />,
        },
        {
          path: '/login/redirect/google',
          element: <LoginReturnPage type={'GOOGLE'} urlAuthCodeParam={'code'} />,
        },
        {
          path: '/login/redirect/apple',
          element: <LoginReturnPage type={'APPLE'} urlAuthCodeParam={'code'} />,
        },
        {
          path: 'ballers/:ballerId',
          element: (
            <UserRoute>
              <BallerDetail />
            </UserRoute>
          ),
        },
        MINT_ACTIVE
          ? {
              path: 'mint',
              element: (
                <UserRoute>
                  <Mint />
                </UserRoute>
              ),
            }
          : {},
        CLAIM_ACTIVE
          ? {
              path: 'claim',
              element: (
                <UserRoute>
                  <Claim />
                </UserRoute>
              ),
            }
          : {},
      ],
    },
  ]);

  useLayoutEffect(() => {
    if (process.env.REACT_APP_CACHE_HASH && localStorage.getItem('MC_CACHE_HASH') !== process.env.REACT_APP_CACHE_HASH) {
      localStorage.clear();
      sessionStorage.clear();
      localStorage.setItem('MC_CACHE_HASH', process.env.REACT_APP_CACHE_HASH);
      window.location.reload();
    }
  }, []);

  return (
    <RecoilRoot>
      <GlobalStyles />
      <RouterProvider router={router} />
    </RecoilRoot>
  );
};

export default App;
