import React, { SVGProps } from 'react';

export default function CardsIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M16.2256 16.5859L9.28281 20.4375L2.665 9.41406L9.60375 5.5625L16.2256 16.5859ZM8.56781 23L10.2578 22.0625L17.2006 18.207L18.8906 17.2695L17.9156 15.6445L11.2937 4.625L10.3188 3L8.62875 3.9375L0 8.73047L7.59281 21.375L8.56781 23ZM13 21.9844V23H26V5.5H13.3209L20.0159 16.6484L20.6659 17.7305L19.5406 18.3555L13 21.9844Z'
        fill='currentColor'
      />
    </svg>
  );
}
