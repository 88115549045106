import DetailBackButton from './subcomponents/DetailBackButton';
import { useParams } from 'react-router-dom';
import { useContext, useEffect, useMemo, useState } from 'react';
import { RaffleProps, ShopItemProps, ShopItemVariant, useShopApi } from '../../../services/shop';
import { useIsApiReady } from '../../../services/ApiProvider';
import DetailImageGallery from './subcomponents/DetailImageGallery';
import DetailPageTags from './subcomponents/DetailPageTags';
import DetailPageCountdown from './subcomponents/DetailPageCountdown';
import {
  DetailPageContainer,
  DetailPageContent,
  DetailPageInfoWrapper,
  DetailPageLayout,
  DetailPageLoadingAnimation,
  DetailPagePurchaseInfo,
  DetailPageTitle,
} from './subcomponents/DetailPageStyledComponents';
import DetailPagePrice from './subcomponents/DetailPagePrice';
import DetailPageVariantSelect from './subcomponents/DetailPageVariantSelect';
import DetailPageAccordion from './subcomponents/DetailPageAccordion';
import DetailPageRecommendations from './subcomponents/DetailPageRecommendations';
import { Translations } from '../../../utils/Translations';
import { useAccount } from 'wagmi';
import { useRecoilValue } from 'recoil';
import { useForm } from 'react-hook-form';
import { Notifications } from '../../layout/app/Layout';
import useToast from '../../../hooks/useToast';
import BuyOrConnectButton from './subcomponents/BuyOrConnectButton';
import DetailPageBuyModal from './subcomponents/DetailPageBuyModal';
import DetailPageAmountSelect from './subcomponents/DetailPageAmountSelect';
import InfoIcon from '../../icons/InfoIcon';
import Wysiwyg from '../../common/Wysiwyg';
import { AccountState } from '../../../states/AppData';
import { useUserApi } from '../../../services/user';

export default function RaffleDetail() {
  const { id } = useParams();

  const shopApi = useShopApi();
  const isApiReady = useIsApiReady();
  const { address } = useAccount();
  const { addToast } = useToast();
  const userApi = useUserApi();

  const notifications = useContext(Notifications);
  const accountState = useRecoilValue(AccountState);

  const [loading, setLoading] = useState<boolean>(true);
  const [openBuyModal, setOpenBuyModal] = useState(false);
  const [raffleItem, setRaffleItem] = useState<RaffleProps | null>(null);
  const [recommendations, setRecommendations] = useState<ShopItemProps[]>([]);
  const [selectedVariant, setSelectedVariant] = useState<ShopItemVariant | null>(null);
  const [selectedAmount, setSelectedAmount] = useState<number>(1);

  const form = useForm();
  const selectedVariantId = form.watch('variant');

  useEffect(() => {
    if (raffleItem && raffleItem.item) {
      setSelectedVariant(raffleItem.item.variants.find(({ id }) => id === selectedVariantId) || null);
    }
  }, [selectedVariantId, raffleItem]);

  // TODO @daniel add correct shippingCost
  const shippingCost = useMemo(() => {
    return 0;
  }, [raffleItem]);

  const purchaseInfo = useMemo(() => {
    if (raffleItem && raffleItem.item) {
      if (raffleItem.item.paymentForProductRequired && raffleItem.item.paymentForShippingRequired) {
        return Translations.detailPage.productInfo.raffle.buyingRights.shipping(raffleItem.item.retailPrice, shippingCost);
      } else if (raffleItem.item.paymentForProductRequired && !raffleItem.item.paymentForShippingRequired) {
        return Translations.detailPage.productInfo.raffle.buyingRights.noShipping(raffleItem.item.retailPrice);
      } else if (!raffleItem.item.paymentForProductRequired && raffleItem.item.paymentForShippingRequired) {
        return Translations.detailPage.productInfo.raffle.directBuy.shipping(shippingCost);
      } else if (!raffleItem.item.paymentForProductRequired && !raffleItem.item.paymentForShippingRequired) {
        return Translations.detailPage.productInfo.raffle.directBuy.noShipping;
      }
    } else return '';
  }, [raffleItem]);

  const saleStarted = useMemo(() => {
    const startDate = raffleItem?.startDate;
    return startDate ? Boolean(new Date(startDate) < new Date()) : true;
  }, [raffleItem]);

  const saleEnded = useMemo(() => {
    const endDate = raffleItem?.endDate;
    return endDate ? Boolean(new Date(endDate) < new Date()) : false;
  }, [raffleItem]);

  const checkWhitelist = useMemo(() => {
    const whitelist = raffleItem?.allowList || [];
    const isWhitelistActive = Boolean(whitelist.length > 0);
    const isWalletOnWhitelist = Boolean(whitelist.find(({ wallet }) => wallet === address));
    return Boolean(!isWhitelistActive || (isWhitelistActive && isWalletOnWhitelist));
  }, [raffleItem, address]);

  const isPurchasable = useMemo(() => {
    const accountLevel = Array.isArray(accountState?.account.level) ? accountState?.account.level : ['free'];
    const hasCorrectLevel = accountLevel?.includes('legacy') || Boolean(accountLevel?.includes(raffleItem?.category?.type!));
    const hasEnoughCoins = Boolean(
      accountState?.account.points && selectedVariant && accountState?.account.points >= selectedVariant?.price,
    );

    return Boolean(hasCorrectLevel && hasEnoughCoins && checkWhitelist && saleStarted && !saleEnded && selectedVariant);
  }, [accountState, selectedVariant, raffleItem, checkWhitelist, saleEnded, saleStarted]);

  const fetchRaffle = useMemo(
    () => async () => {
      try {
        const response = await shopApi.getRaffle(id!);
        setRaffleItem(response);
      } catch (error) {
        console.error('Error fetching product list:', error);
      } finally {
        setLoading(false);
      }
    },
    [id],
  );

  const fetchRecommendations = useMemo(
    () => async () => {
      try {
        const response = await shopApi.getRecommendations(id);
        setRecommendations(response);
      } catch (error) {
        console.error('Error fetching recommendations:', error);
      }
    },
    [id],
  );

  useEffect(() => {
    fetchRaffle();
    fetchRecommendations();
  }, []);

  const buy = async () => {
    if (isApiReady) {
      try {
        await shopApi.buyRaffleTickets(id!, selectedVariant!.id, selectedAmount);
        if (notifications?.successfulShopBuyNotification) {
          addToast({
            ...notifications.successfulShopBuyNotification,
            type: 'success',
          });
        }
      } catch (error) {
        console.log(error);
        if (notifications?.failedShopBuyNotification) {
          addToast({
            ...notifications.failedShopBuyNotification,
            type: 'error',
          });
        }
      } finally {
        setOpenBuyModal(false);
        await fetchRaffle();
        await userApi.refreshAccountState();
        // TODO @daniel redirect to profile or history?
      }
    }
  };

  return (
    <DetailPageLayout>
      <DetailBackButton />

      {loading ? (
        <DetailPageLoadingAnimation />
      ) : (
        raffleItem &&
        raffleItem.item && (
          <>
            <DetailPageContainer>
              <DetailImageGallery images={raffleItem.item.images} />

              <DetailPageContent form={form} onSubmit={() => setOpenBuyModal(true)}>
                <DetailPageTags
                  tags={[
                    'Raffle',
                    raffleItem.category.label,
                    raffleItem.item.paymentForProductRequired ? 'Buying Right' : 'Direct Purchase',
                  ]}
                />

                <DetailPageTitle>{raffleItem.item.name}</DetailPageTitle>

                <DetailPageInfoWrapper>
                  <DetailPageCountdown startDate={raffleItem.startDate} endDate={raffleItem.endDate} />
                </DetailPageInfoWrapper>

                <DetailPagePrice price={(selectedVariant?.price || raffleItem.item.price) * selectedAmount} />

                <DetailPagePurchaseInfo>
                  <InfoIcon /> <Wysiwyg content={purchaseInfo?.split('|||')[0] || ''} />
                </DetailPagePurchaseInfo>

                <DetailPageVariantSelect variants={raffleItem.item.variants} disabled={!saleStarted || saleEnded} />

                <DetailPageAmountSelect amount={selectedAmount} setAmount={setSelectedAmount} disabled={!saleStarted || saleEnded} />

                <BuyOrConnectButton isPurchasable={isPurchasable} label={'Buy Ticket'} />

                <DetailPageAccordion purchaseInfo={purchaseInfo || ''} productInfo={raffleItem.item.description || ''} />
              </DetailPageContent>
            </DetailPageContainer>

            <DetailPageRecommendations recommendations={recommendations} />

            {openBuyModal && selectedVariant && (
              <DetailPageBuyModal
                close={() => setOpenBuyModal(false)}
                buy={buy}
                item={raffleItem.item}
                amount={selectedAmount}
                purchaseInfo={purchaseInfo || ''}
                selectedVariant={selectedVariant}
              />
            )}
          </>
        )
      )}
    </DetailPageLayout>
  );
}
