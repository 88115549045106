// General
import { SVGProps } from 'react';

interface IAlienIconProps extends SVGProps<SVGSVGElement> {
  eyeColor?: string;
}

export default function AlienIcon(props: IAlienIconProps) {
  const { eyeColor } = props;

  return (
    <svg {...props} viewBox='0 0 42 50' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M10.493 16.3055C10.493 9.69444 15.3618 4.69444 20.986 4.69444C26.6103 4.69444 31.4791 9.69444 31.4791 16.3055C31.4791 19.6111 29.7722 23.0833 27.1699 26.3055C24.9874 29 22.525 31.1389 20.986 32.3333C19.4471 31.1389 16.9847 29 14.8022 26.3055C12.1999 23.0833 10.493 19.6389 10.493 16.3055ZM20.986 0C12.7036 0 5.98805 7.30555 5.98805 16.3055C5.98805 26.1666 15.9774 34.3611 19.0274 36.6389C19.587 37.0555 20.2865 37.2777 20.986 37.2777C21.6856 37.2777 22.3571 37.0833 22.9447 36.6389C25.9947 34.3611 35.984 26.1666 35.984 16.3055C35.984 7.30555 29.2685 0 20.986 0Z'
        fill='currentColor'
      />
      <path
        d='M0 47.5C0 48.8889 1.09127 50 2.4064 50H39.5936C40.9087 50 42 48.8889 42 47.5C42 40.5278 36.7955 34.8333 30.1919 34.4167C28.0653 36.5833 26.0786 38.1944 24.8754 39.0833H29.4084C33.1579 39.0833 36.2918 41.7222 37.2432 45.3333H4.75683C5.68021 41.7222 8.84211 39.0833 12.5916 39.0833H17.1246C15.9214 38.1944 13.9347 36.5833 11.8081 34.4167C5.23251 34.8333 0 40.5278 0 47.5Z'
        fill='currentColor'
      />
      <path
        d='M19.5023 21.1467C19.5023 18.2933 17.2068 16 14.3515 16H12.8398C12.3639 16 12 16.3733 12 16.8533C12 19.7067 14.2955 22 17.1509 22H18.6625C19.1384 22 19.5023 21.6267 19.5023 21.1467Z'
        fill={eyeColor || 'currentColor'}
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M27.6485 16C24.8212 16 22.4977 18.2933 22.4977 21.1467C22.4977 21.6267 22.8896 22 23.3375 22H24.8491C27.6765 22 30 19.7067 30 16.8533C30 16.3733 29.6081 16 29.1602 16H27.6485Z'
        fill={eyeColor || 'currentColor'}
      />
    </svg>
  );
}
