// General
import { FC, PropsWithChildren, useContext } from 'react';
import styled, { css } from 'styled-components';

// Helpers
import { WalletConnectTranslations } from '../layout/app/Layout';

// Components
import Close from '../icons/CloseIcon';

// Styles
import { BREAKPOINT_MD, BREAKPOINT_SM } from '../../styles/Breakpoints';

const StyledModal = styled.div`
  position: relative;
  width: MIN(870px, calc(100% - var(--content-indent)));
  max-height: calc(100% - var(--content-indent));
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(0.5rem);
  border-radius: 0.625rem;
  padding-block: var(--padding-block);
  padding-inline: var(--padding-inline);
  border: 2px solid rgba(255, 255, 255, 0.5);
  overflow-y: auto;

  --padding-block: 2rem;
  --padding-inline: 2rem;

  ${BREAKPOINT_SM} {
    --padding-inline: 3.75rem;
  }

  ${BREAKPOINT_MD} {
    --padding-block: 3.75rem;
    --padding-inline: 10rem;
  }
`;

const CloseButton = styled(Close)`
  color: var(--color-text-default);
  cursor: pointer;
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  background: unset;
  border: 0;

  @media (hover: hover) {
    :hover {
      cursor: pointer;
      color: var(--color-primary-750);
    }
  }
`;

const Cover = css`
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 999;
`;

const Overlay = styled.div`
  ${Cover};
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Backdrop = styled.div`
  ${Cover};
  position: absolute;
  pointer-events: auto;
  z-index: -1;

  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(0.625rem);
`;

export interface IModalProps {
  className?: string;
  close: () => void;
}

const Modal: FC<PropsWithChildren<IModalProps>> = (props) => {
  const { close, children, className } = props;
  const t = useContext(WalletConnectTranslations);

  return (
    <Overlay>
      <Backdrop onClick={close} />

      <StyledModal className={className}>
        <CloseButton onClick={close} aria-label={t?.close}>
          <Close />
        </CloseButton>

        {children}
      </StyledModal>
    </Overlay>
  );
};

export default Modal;
