import styled from 'styled-components';
import { useRecoilValue } from 'recoil';
import { FontFamilies } from '../../../../styles/Fonts';
import AlertIcon from '../../../icons/AlertIcon';
import { Button } from '../../../common/Button';
import { BREAKPOINT_MD } from '../../../../styles/Breakpoints';
import { AddressState, AuthTokenState } from '../../../../states/AppData';
import { useMemo } from 'react';
import { useIsApiReady } from '../../../../services/ApiProvider';
import LinkHandler from '../../../common/LinkHandler';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 1.25rem;
  margin-block-end: 2rem;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1.25rem;
`;

const ShopDetailButton = styled(Button)`
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 0.5rem;

  ${BREAKPOINT_MD} {
    width: auto;
    padding-inline: 2.5rem;
  }
`;

const Limit = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-family: ${FontFamilies.headline};
  font-size: 1.125rem;
  font-weight: 700;
  white-space: nowrap;

  svg {
    font-size: 1.5rem;
  }
`;

const AddressNotification = styled(LinkHandler)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.625rem;
  color: var(--color-signal-yellow);
  border: 1px solid var(--color-signal-yellow);
  border-radius: 0.625rem;
  justify-content: flex-start;
  font-size: 0.875rem;
  text-align: start;

  :hover {
    text-decoration: none;
  }

  svg {
    font-size: 1.5rem;
  }

  @media (hover: hover) {
    &:hover {
      span {
        text-decoration: underline;
      }
    }
  }
`;

interface IBuyOrConnectButton {
  isPurchasable: boolean;
  label: string;
  maxAmount?: number;
  ownedItems?: number;
}

export default function BuyOrConnectButton(props: IBuyOrConnectButton) {
  const { isPurchasable, label, maxAmount, ownedItems } = props;
  const addressState = useRecoilValue(AddressState);

  const isApiReady = useIsApiReady();

  const hasAddress = useMemo(() => Boolean(addressState?.id), [addressState]);

  return (
    <Wrapper>
      <ButtonWrapper>
        <ShopDetailButton disabled={!isPurchasable || !hasAddress || !isApiReady}>{label}</ShopDetailButton>

        {maxAmount && <Limit>Limit: {ownedItems === null ? maxAmount : ownedItems + '/' + maxAmount}</Limit>}
      </ButtonWrapper>

      {isApiReady && !hasAddress && (
        <AddressNotification to={'/profile/address'}>
          <AlertIcon />
          <p>
            You have to add your <span>address</span> before being able to purchase items from the shop.
          </p>
        </AddressNotification>
      )}
    </Wrapper>
  );
}
