import styled from 'styled-components';
import { FontFamilies } from '../../../../styles/Fonts';
import { BREAKPOINT_MD } from '../../../../styles/Breakpoints';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
  margin-block-end: 0.5rem;
`;

const Tag = styled.span`
  font-family: ${FontFamilies.headline};
  font-size: 0.875rem;
  border-radius: 4px;
  background: var(--color-text-default);
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2);
  color: var(--color-text-highlight);
  padding-inline: 0.5rem;
  padding-block: 0.25rem;
  line-height: 1;
  font-weight: 600;

  ${BREAKPOINT_MD} {
    font-size: 1rem;
  }
`;

interface IDetailPageTagsProps {
  tags: string[];
}

export default function DetailPageTags(props: IDetailPageTagsProps) {
  const { tags } = props;

  return (
    <Wrapper>
      {tags.map((tag, index) => (
        <Tag key={index}>{tag}</Tag>
      ))}
    </Wrapper>
  );
}
