import { useApi } from './ApiProvider';

const BASE_PATH = '/v2/shop';

interface AllowListEntry {
  wallet: string;
}

export interface ShopHistoryItemProps {
  id: string;
  item: ShopItemProps;
  itemVariant: string;
  purchasedAt: string;
  purchased: boolean;
  redeemed: boolean;
  redeemedAt: string;
  createdAt: string;
  updatedAt: string;
  paymentLink?: string;
  shippingLink?: string;
  shopLink?: string;
}

export interface ShopItemVariant {
  id: string;
  name?: string;
  images: {
    id: string;
    image: ShopItemImageProps;
  }[];
  amount: number;
  availableAmount: number;
  price: number;
  attributes: ShopItemVariantAttribute[];
}

export interface ShopItemVariantAttribute {
  id: string;
  type: string;
  value: string;
}

export interface ShopItemProps {
  id: string;
  name: string;
  category: ShopItemCategory;
  description?: string;
  introText?: { children: { [key: string]: string }[] }[];
  endDate: string;
  startDate: string;
  allowList: AllowListEntry[];
  variants: ShopItemVariant[];
  highlighted: boolean;
  previewImage: ShopItemImageSizeProps;
  images: {
    id: string;
    image: ShopItemImageProps;
  }[];
  retailPrice: number;
  price: number;
  paymentForProductRequired: boolean;
  paymentForShippingRequired: boolean;
  maxPurchasableQuantity?: number;
  ownedItems?: number;
  displayInShop: boolean;
}

export interface ShopItemImageSizeProps {
  filename: string;
  filesize: number;
  height: number;
  mimeType: string;
  url: string;
  width: number;
}

export interface ShopItemImageProps extends ShopItemImageSizeProps {
  id: string;
  alt: string;
  prefix: string;
  sizes: {
    shop: ShopItemImageSizeProps;
    thumbnail: ShopItemImageSizeProps;
    webp: ShopItemImageSizeProps;
  };
}

export interface ShopItemCategory {
  id: string;
  //level: string;
  type: string;
  label: string;
  displayOrder: number;
  //name: string;
}

export interface RaffleProps {
  id: string;
  category: ShopItemCategory;
  item: ShopItemProps;
  startDate: string;
  endDate: string;
  allowList: AllowListEntry[];
}

export interface RaffleTicketProps {
  id: string;
  itemVariant: string;
  raffle: RaffleProps;
  amount: number;
  winner: boolean;
  wallet: string;
  createdAt: string;
  winningItemId?: string;
}

export interface AddressProps {
  city: string;
  company: string;
  country: string;
  email: string;
  firstname: string;
  lastname: string;
  phone: string;
  postalCode: string;
  street: string;
  housenumber: string;
}

export interface OrderProps {
  id: string;
  address: AddressProps;
  item: ShopItemProps;
  itemVariant: string;
  wallet: string;
  status: string;
  historyItemId: string;
}

export const useShopApi = () => {
  const apiInstance = useApi();
  const getShopItems = async () => {
    return (await apiInstance.get(`${BASE_PATH}/items`)).data;
  };

  const getShopItemsByCategory = async (category: string) => {
    return (await apiInstance.get(`${BASE_PATH}/items/filtered/${category}`)).data;
  };

  const getShopItem = async (id: string) => {
    return (await apiInstance.get(`${BASE_PATH}/items/${id}`)).data;
  };

  const getShopItemCategories = async () => {
    return (await apiInstance.get(`${BASE_PATH}/categories`)).data;
  };

  const buyItem = async (itemId: string, itemVariantId: string) => {
    return (
      await apiInstance.post(`${BASE_PATH}/items/${itemId}/buy`, {
        itemId,
        itemVariantId,
      })
    ).data;
  };

  const buyRaffleTickets = async (raffleId: string, itemVariantId: string, amount: number) => {
    return (
      await apiInstance.post(`${BASE_PATH}/raffles/${raffleId}/buy`, {
        itemVariantId,
        amount,
      })
    ).data;
  };

  const getShopHistory = async () => {
    return (await apiInstance.get(`${BASE_PATH}/history`)).data;
  };

  const getOrders = async () => {
    return (await apiInstance.get(`${BASE_PATH}/orders`)).data;
  };

  const getHistoryItem = async (id: string) => {
    return (await apiInstance.get(`${BASE_PATH}/history/items/${id}`)).data;
  };

  const getRaffles = async () => {
    return (await apiInstance.get(`${BASE_PATH}/raffles`)).data;
  };

  const getRaffleTickets = async () => {
    return (await apiInstance.get(`${BASE_PATH}/raffles/tickets`)).data;
  };

  const getRaffleTicket = async (id: string) => {
    return (await apiInstance.get(`${BASE_PATH}/raffles/ticket/${id}`)).data;
  };

  const getRafflesByCategory = async (categoryId: string) => {
    return (await apiInstance.get(`${BASE_PATH}/raffles/filtered/${categoryId}`)).data;
  };

  const getRaffle = async (id: string) => {
    return (await apiInstance.get(`${BASE_PATH}/raffles/${id}`)).data;
  };

  const getRecommendations = async (id?: string) => {
    return (await apiInstance.get(`${BASE_PATH}/recommendations?id=${id}`)).data;
  };

  const getInventory = async () => {
    return (await apiInstance.get(`${BASE_PATH}/inventory`)).data;
  };

  return {
    getShopItems,
    getShopItemsByCategory,
    getShopItem,
    getShopItemCategories,
    buyItem,
    buyRaffleTickets,
    getShopHistory,
    getOrders,
    getHistoryItem,
    getRaffles,
    getRaffleTickets,
    getRaffleTicket,
    getRafflesByCategory,
    getRaffle,
    getRecommendations,
    getInventory,
  };
};
